import { Environment } from './environment.model';

export const environment: Environment = {
    fayeUrl: 'https://ws.baresto.fr',
    name: 'production',
    tokenCookieName: 'JWT',
    sentryDsn: 'https://680108615be8484a05a16a334975efa7@sentry.infra.ik.spiriit.cloud/35',
    sentryRelease: '2025.01.09',
    analytics: 'GTM-WMNRMLK',
    markerIo: {
        project: '671f9f367a3b33b4b92d8582',
    },
    release: '2025.01.09',
    barestoUser: 'démobaresto',
    agrimer: {
        baseUrl: 'https://baresto-agrimer.sf.preprod.spiriit.tech',
        token: '2Z3QkAiC52zC+pG#9oF0k|Ak!YKVt}l+r-UT1GNOP0-kkLOvGmRL1Gac',
    },
};
